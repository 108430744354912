<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item label="年级" label-width="50px" prop="ninji">
				  <el-select v-model="queryForm.nianji" placeholder="请选择年级" style="width: 100%;">
				    <el-option :value="item.id" :label="item.name" v-for="(item,index) in nianji" :key="item.id"/>
				  </el-select>
				</el-form-item>
				<el-form-item label="学科" label-width="50px" prop="xueke">
				  <el-select v-model="queryForm.xueke" placeholder="请选择学科" style="width: 100%;">
				    <el-option :value="item.id" :label="item.name" v-for="(item,index) in xueke" :key="item.id"/>
				  </el-select>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="学期" align="center" min-width="100">
				  <template slot-scope="scope">
					<span>{{ scope.row.year +' '+ scope.row.tag}}</span>
				  </template>
				</el-table-column>
				</el-table-column>
				<el-table-column label="姓名" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.t_name || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="年级" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.nianji || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="学科" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.xueke || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="类型" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.type == 1 ? '培训工作' : '青蓝工程'}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="分数" min-width="110" align="center" >
				  <template slot-scope="scope">
					<span>{{ scope.row.score || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">已审核</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="warning">待审核</el-tag>
					<el-tag v-if="scope.row.status === 3" size="mini" type="danger">驳回</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="160">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="查看" placement="top">
					  <el-button type="primary" icon="el-icon-view" size="mini" circle @click="handleView(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="撤回" placement="top" v-if="scope.row.status == 1">
					  <el-button type="danger" icon="el-icon-refresh-left" size="mini" circle @click="handleRecall('jspx',scope.row)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'user_jspx',
				autoRequest: false,
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
					nianji:'',
					xueke:'',
					role_id:this.VueCookies.get('role_id'),
				},
				nianji:[],
				xueke:[],
			}
		},
		mounted() {
			this.getList()
			this.getNianji()
			this.getXueke()
		},
		methods:{
			getList() {
			  this.loading = true
			  this.axios.get(`/manage/${this.preUrl}/operate_list`, {
			    params: this.queryForm
			  }).then(res => {
			    this.total = parseInt(res.total)
			    this.list = res.data
			    this.loading = false
			  }).catch(err => {
			    this.loading = false
			  })
			},
			handleView(row){
				this.$router.push({
				  path:"/jspx_detail",
				  query: {
					  id:row.id
				  }
				})
			}
		}
	}
</script>

<style>
</style>